<template>
  <v-data-table
    :headers="headers"
    :footer-props="footerProps"
    :items="contactFields"
    :loading="isLoading"
    :server-items-length="contactFieldsCount"
    :options.sync="options"
  >
    <template v-slot:item.name="{ item }">
      {{ item.attributes.name }}
    </template>
    <template v-slot:item.label="{ item }">
      {{ item.attributes.label }}
    </template>
    <template v-slot:item.required="{ item }">
      <v-icon :color="item.attributes.required ? 'green' : 'red'">
        {{ item.attributes.required ? "mdi-check-circle" : "mdi-cancel" }}
      </v-icon>
      {{ item.attributes.required ? "YES" : "NO" }}
    </template>

    <template v-slot:item.view="{ item }">
      <v-btn
        :to="{
          name: 'ContactFieldsEdit',
          params: { id: item.id },
        }"
        small
        color="primary"
        class="mr-1 float-right"
      >
        Edit
        <v-icon aria-label="Edit Icon" class="ml-1">mdi-pencil</v-icon>
      </v-btn>
      <span v-if="!item.attributes.isDefault">
        <confirm-dialog ref="confirmDelete"></confirm-dialog>
        <v-btn @click="openConfirm(item.id)" small class="btn-delete mx-1">
          Delete
          <v-icon aria-label="Delete Icon" class="ml-1">mdi-delete</v-icon>
        </v-btn>
      </span>
    </template>

    <template v-slot:no-data>
      <no-data-alert />
    </template>
  </v-data-table>
</template>

<script>
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import { humanize } from "@utils/filters/stringHelpers";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";

export default {
  name: "ContactFieldsTable",
  filters: { humanize },
  components: {
    NoDataAlert,
    ConfirmDialog,
  },
  props: {
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    contactFields: {
      type: Array,
      required: true,
      default: () => [],
    },
    contactFieldsCount: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Label",
          value: "label",
        },
        {
          text: "Required",
          value: "required",
        },
        {
          text: "Action",
          value: "view",
          align: "center",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapState(["footerProps"]),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }
      return data;
    },
  },
  methods: {
    ...mapActions("contactFields", ["deleteContactField"]),
    removeContactField(id) {
      this.deleteContactField(id).then(([success]) => {
        if (success) {
          this.$emit("updateContactFields", this.pageAndSortData);
        }
      });
    },
    openConfirm(id) {
      this.$refs.confirmDelete
        .open("Confirm", "Are you sure you want to delete this ?")
        .then((confirm) => {
          if (confirm) {
            this.removeContactField(id);
          }
        });
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;
        this.$emit("updateContactFields", currentValue);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.btn-delete {
  background-color: #ad330f !important;
  color: white;
}
</style>
