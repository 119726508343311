<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <contact-fields-table
          :contactFields="contactFields"
          :contactFieldsCount="contactFieldsCount"
          :isLoading="isLoading"
          @updateContactFields="handleContactFieldsUpdate"
        >
        </contact-fields-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ContactFieldsTable from "@admin/components/contact_fields/Table";

export default {
  name: "ContactFieldsIndex",
  components: {
    ContactFieldsTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("contactFields", ["contactFields", "contactFieldsCount"]),
    ...mapGetters(["acceptRoles"]),
  },
  beforeDestroy() {
    this.clearContactFields();
  },
  methods: {
    ...mapActions("contactFields", ["getContactFields", "clearContactFields"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    async handleContactFieldsUpdate(options) {
      this.isLoading = true;
      await this.getContactFields(options);
      this.isLoading = false;
    },
  },
  async mounted() {
    if (this.contactFields === undefined || this.contactFields.length === 0) {
      this.isLoading = true;
      await this.getContactFields({
        sortBy: ["label"],
      });
      this.isLoading = false;
    }
    this.setBreadcrumbs([
      {
        text: "Home",
        disabled: false,
        href: "/admin",
      },
      {
        text: "Contact Fields",
        disabled: true,
        href: "",
      },
    ]);
    this.setTitleObject("Contact Fields");
    this.setActionButtons([
      {
        attributes: {
          to: "/contact-fields/add/",
        },
        component: "AddButton",
        text: "Add Field",
        index: 0,
      },
    ]);
  },
  destroyed() {
    this.clearContactFields();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
